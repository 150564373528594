
.elevate-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(5.28vw, 76px);
}

.elevate-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.elevate-header-title {
    font-size: min(8.33vw, 120px);
    line-height: min(9.10vw, 131px);
    font-family: 'Klein Condensed Trial';
    color: black;
    text-align: center;
    letter-spacing: -4px;

}

.arrow-absolute-container {
    position: absolute;
    top: 10%;
    left: -28%;
    width: 246px;
    height: 299px;
}

.thing-absolute-container {
    position: absolute;
    bottom: 10%;
    right: 22%;
    width: 66px;
    height: 55px;
}

.elevate-cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: url('../../assets/landing/background.png');
    background-position: center;
    background-repeat: no-repeat;
    padding-top: min(1.81vw, 26px);
    padding-left: min(5.90vw, 85px);
    padding-right: min(5.90vw, 85px);
    padding-bottom: min(2.78vw, 40px);
    height: min(47.08vw, 678px);
    
}

.elevate-cards-container {
    display: flex;
    gap: min(1.25vw, 18px);
    padding-top: min(4.03vw, 58px);
    box-sizing: border-box;
}

.elevate-card {
    padding-top: min(1.11vw, 16px);
    padding-left: min(1.11vw, 16px);
    padding-right: min(1.11vw, 16px);
    padding-bottom: min(2.36vw, 34px);
    border-radius: min(1.67vw, 24px);
    background: rgba(0, 0, 0, 0.3);
    transition: height ease 1s;
    box-sizing: border-box;
    max-height: min(29.58vw, 426px);
    transition: max-height ease 0.5s;
    
    
}

.elevate-card-frame {
    position: relative;
    width: min(20.83vw, 300px);
    height: min(20.83vw, 300px);
    border-radius: min(1.67vw, 24px);
    background: rgba(0, 0, 0, 0.3);

    
}

.elevate-card-idle-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(15px);
    z-index: 9;
    opacity: 1;
    transition: opacity ease 0.5s;
    border-radius: min(1.67vw, 24px);
    overflow: hidden;
}

.elevate-card-hidden-cover {
    position: relative;
    z-index: 0;
    /*
    width: min(20.83vw, 300px);
    height: min(20.83vw, 300px);
    border-radius: min(1.67vw, 24px);
    background: rgba(0, 0, 0, 0.3);
    */
}

.elevate-card-title {
    font-family: "Klein Condensed Trial";
    font-size: min(2.08vw, 30px);
    line-height: min(1.94vw, 27.9px);
    text-transform: uppercase;
    color: white;
    text-align: center;
    max-width: min(10.83vw, 156px);
}

.elevate-card-subtitle {
    max-height: 1px;
    opacity: 0;
    font-family: "Mont";
    font-size: var(--desktop-16px);
    line-height: min(1.32vw, 19px);
    color: white;
    text-align: center;
    max-width: min(20.42vw, 294px);
    transition: max-height ease 0.5s, opacity ease 0.5s;
}

.elevate-card-title-container {
    margin-top: min(1.88vw, 27px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(1.11vw, 16px);
}

.elevate-card-icon {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 9;
}

.know-more-green {
    width: fit-content;
    height: min(2.99vw, 43px);
    display: flex;
    align-items: center;
    padding-left: min(2.99vw, 43px);
    padding-right: min(2.99vw, 43px);
    border-radius: min(1.09vw, 15.76px);
    background: rgba(103, 155, 46, 1);
    font-family: "Klein Condensed Trial";
    font-weight: 800;
    font-size: min(1.53vw, 22px);
    letter-spacing: -1px;
    color: rgba(191, 255, 120, 1);
    margin-bottom: min(2.85vw, 41px);
    transition: background-color ease 0.3s, color ease 0.3s;
}

.know-more-green:hover {
    background: rgba(191, 255, 120, 1);
    color: rgba(103, 155, 46, 1);
}

@media screen and (max-width: 478px) {

        
    .card-slider-wrapper {
        overflow: visible;
        height: auto;
        margin-top: 20px;
    }

    .elevate-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: min(25.6vw, 106px);
        
    }

    .elevate-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .elevate-header-title {
        font-size: min(13vw, 54px);
        line-height: min(12.1vw, 50px);
        font-family: 'Klein Condensed Trial';
        color: black;
        text-align: center;
        letter-spacing: -4px;
    }

    .arrow-absolute-container {
        position: absolute;
        top: -50%;
        left: -45%;
        width: min(23.2vw, 96.25px);
        height: min(28.2vw, 117px);
    }

    .thing-absolute-container {
        position: absolute;
        bottom: 65%;
        right: -12%;
        width: 25px;
        height: auto;
    }

    .elevate-cards-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-image: url('../../assets/landing/background.png');
        background-position: center;
        background-repeat: no-repeat;
        padding-top: min(1.81vw, 26px);
        padding-left: min(5.90vw, 85px);
        padding-right: min(5.90vw, 85px);
        padding-bottom: min(2.78vw, 40px);
        height: min(47.08vw, 678px);
        
    }

    .elevate-cards-container {
        display: flex;
        gap: min(1.25vw, 18px);
        padding-top: min(4.03vw, 58px);
        box-sizing: border-box;
    }

    .elevate-card {
        padding-top: min(3.86vw, 16px);
        padding-left: min(3.86vw, 16px);
        padding-right: min(3.86vw, 16px);
        padding-bottom: min(7.72vw, 34px);
        border-radius: min(5.79vw, 24px);
        background: rgba(0, 0, 0, 0.3);
        transition: height ease 1s;
        box-sizing: border-box;
        max-height: none;
        transition: max-height ease 0.5s;
        
        
    }

    .elevate-card-frame {
        position: relative;
        width: min(72.4vw, 300px);
        height: min(72.4vw, 300px);
        border-radius: min(5.79vw, 24px);
        background: transparent;
    }

    .elevate-card-idle-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        backdrop-filter: blur(1px);
        z-index: 9;
        opacity: 1;
        transition: opacity ease 0.5s;
        border-radius: min(1.67vw, 24px);
        overflow: hidden;
       display: none;
    }

    .elevate-card-hidden-cover {
        position: relative;
        z-index: 0;
        
        /*
        width: min(20.83vw, 300px);
        height: min(20.83vw, 300px);
        border-radius: min(1.67vw, 24px);
        background: rgba(0, 0, 0, 0.3);
        */
    }

    .elevate-card-title {
        font-family: "Klein Condensed Trial";
        font-size: min(7.24vw, 30px);
        line-height: min(6.73vw, 27.9px);
        text-transform: uppercase;
        color: white;
        text-align: center;
        max-width: 90%;
    }

    .elevate-card-subtitle {
        max-height: none;
        opacity: 1;
        font-family: "Mont";
        font-size: min(3.8vw, 16px);
        line-height: min(4.58vw, 19px);
        color: white;
        text-align: center;
        max-width: min(71vw, 294px);
        transition: max-height ease 0.5s, opacity ease 0.5s;
    }

    .elevate-card-title-container {
        margin-top: min(1.88vw, 27px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: min(3.86vw, 16px);
    }

    .elevate-card-icon {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        z-index: 9;
    }

    .know-more-green {
        width: fit-content;
        height: min(2.99vw, 43px);
        display: flex;
        align-items: center;
        padding-left: min(2.99vw, 43px);
        padding-right: min(2.99vw, 43px);
        border-radius: min(1.09vw, 15.76px);
        background: rgba(103, 155, 46, 1);
        font-family: "Klein Condensed Trial";
        font-weight: 800;
        font-size: min(1.53vw, 22px);
        letter-spacing: -1px;
        color: rgba(191, 255, 120, 1);
        margin-bottom: min(2.85vw, 41px);
        transition: background-color ease 0.3s, color ease 0.3s;
    }

    .know-more-green:hover {
        background: rgba(191, 255, 120, 1);
        color: rgba(103, 155, 46, 1);
    }

    
}