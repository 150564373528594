

.whyarg-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(3.19vw, 46px);
    padding-bottom: 10%;
}

.whyarg-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: min(3.19vw, 46px);
}

.whyarg-header {
    font-family: "Klein Condensed Trial";
    font-weight: 800;
    font-size: min(5.17vw, 74.45px);
    line-height: min(4vw, 58.07px);
    text-align: center;
    color: white;
}

.white-line {
    width: 100%;
    height: 1px;
    background-color: white;
}

.whyarg-description {
    font-family: "Mont";
    font-weight: 400;
    max-width: min(59.93vw, 863px);
    word-wrap: break-word;
    color: white;
    font-size: min(1.66vw, 24px);
    line-height: min(2.83vw, 40.8px);
    text-align: center;
}

.cups-image {
    width: auto;
    height: min(20.48vw, 295px);
}

.cup-description {
    margin-top: -2%;
    font-family: "Futura PT";
    font-size: var(--desktop-16px);
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.4em;
    text-align: left;
    width: 224px;
    height: 26px;
    top: 5416.93px;
    left: 617.89px;
    gap: 0px;
    opacity: 0px;
    transform: rotate(-5.36deg);
    color: white;
    
}

@media screen and (max-width: 478px) {

    

.whyarg-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(4.1vw, 17px);
    padding-bottom: 10%;
    margin-top: min(19.3vw, 80px);
}

.whyarg-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: min(3.19vw, 46px);
}

.whyarg-header {
    font-family: "Klein Condensed Trial";
    font-weight: 800;
    font-size: min(13vw, 54px);
    line-height: min(12.1vw, 50.22px);
    text-align: center;
    color: white;
}

.white-line {
    width: 100%;
    height: 1px;
    background-color: white;
}

.whyarg-description {
    font-family: "Mont";
    font-weight: 400;
    max-width: min(92.2vw, 382px);
    word-wrap: break-word;
    color: white;
    font-size: min(3.86vw, 16px);
    line-height: min(6.5vw, 27.2px);
    text-align: center;
}

.cups-image {
    width: auto;
    height: min(42.2vw, 175px);
}

.cup-description {
    margin-top: -2%;
    font-family: "Futura PT";
    font-size: min(2.89vw, 12px);
    font-weight: 500;
    line-height: min(4.63vw, 19.2px);
    letter-spacing: 0.4em;
    text-align: left;
    width: 224px;
    height: 26px;
    width: fit-content;
    gap: 0px;
    opacity: 0px;
    transform: rotate(-5.36deg);
    color: white;
    
}
    
}