

.contact-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(4.3vw, 62px);
    margin-top: min(7vw, 100.95px);
    position: relative;
}

.contact-form-header {

}

.contact-header {
    font-family: "Klein Condensed Trial";
    font-size: min(5.17vw, 74.45px);
    font-weight: 800;
    color: white;
    letter-spacing: -1px;
    text-align: center;
}

.form-element {
    display: flex;
    flex-direction: column;
    gap: min(5vw, 73px);
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: min(40vw, 576.7px);
    
}

.contact-form-input-container {
    display: flex;
    flex-direction: column;
    gap: min(0.34vw, 5px);
    font-family: "Mont";
    font-size: min(1.11vw, 16px);
    color: white;
}

.form-label {
    font-family: inherit;
    font-size: inherit;
    
}

.form-input {
    all: initial;
    border-bottom: 1px solid white;
    color: white;
    font-family: inherit;
    font-size: inherit;
    line-height: 140%;
    transition: border-bottom-color ease 0.5s, color ease 0.5s;
}

.form-input:focus {
    border-bottom-color: #BFFF78;
    color: #BFFF78;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.submit-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.send-button {
    height: min(3.47vw, 50px);
    padding-left: min(2.22vw, 32px);
    padding-right: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(6.94vw, 100px);
    border: 1px solid white;
    font-family: "Poppins";
    font-weight: 500;
    font-size: var(--desktop-16px);
    line-height: 100%;
    letter-spacing: -1px;
    color: black;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
    /*transition-delay: 0.3s;*/
}

.send-button:hover {
    background-color: transparent;
    color: white;
    
} 

.special-hover {
    transition-delay: 0s !important;
}

.special-hover:hover {
    background-color: white;
    color: black;
}

.form-instruction {
    font-family: "Mont";
    color: white;
    font-size: var(--desktop-16px);
}

.success-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 30%;
    opacity: 0;
    visibility: hidden;
    transition: opacity ease 0.3s, visibility ease 0.3s;
    transition-delay: opacity 0.1s;
}

.success-icon {
    width: 150px;
    height: auto;
}

.success-message-header {
    font-size: 35px;
    color: white;
    text-align: center;
    font-family: "Mont";
}



@media screen and (max-width: 478px) {

    
.success-message-header {
    font-size: 24px;
}

.success-message-container {
    padding-top: 0%;
    justify-content: center;
}



.contact-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(9.9vw, 41px);
    margin-top: min(21.2vw, 88px);
}

.contact-form-header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.contact-header {
    font-family: "Klein Condensed Trial";
    font-size: min(13vw, 54px);
    line-height: min(12vw, 50px);
    font-weight: 800;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    word-wrap: break-word;
    max-width: 50%;
}

.form-element {
    display: flex;
    flex-direction: column;
    gap: min(7.99vw, 33.1px);
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: min(92.2vw, 381.8px);
    
}

.contact-form-input-container {
    display: flex;
    flex-direction: column;
    gap: min(0.34vw, 5px);
    font-family: "Mont";
    font-size: min(3.38vw, 14px);
    color: white;
}

.form-label {
    font-family: inherit;
    font-size: inherit;
    
}

.form-input {
    all: initial;
    border-bottom: 1px solid white;
    color: white;
    font-family: inherit;
    font-size: inherit;
    line-height: 140%;
    transition: border-bottom-color ease 0.5s, color ease 0.5s;
}

.form-input:focus {
    border-bottom-color: #BFFF78;
    color: #BFFF78;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.submit-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: min(7.5vw, 31px);
}

.send-button {
    height: min(10.1vw, 42px);
    padding-left: min(6.4vw, 26.5px);
    padding-right: min(6.4vw, 26.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(6.94vw, 100px);
    border: 1px solid white;
    font-family: "Poppins";
    font-weight: 500;
    font-size: min(3.38vw, 14px);
    line-height: 100%;
    letter-spacing: -1px;
    color: black;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
    /*transition-delay: 0.3s;*/
}

.send-button:hover {
    background-color: transparent;
    color: white;
    
} 

.special-hover {
    transition-delay: 0s !important;
}

.special-hover:hover {
    background-color: white;
    color: black;
}

.form-instruction {
    font-family: "Mont";
    color: white;
    font-size: min(3.38vw, 14px);
    align-self: flex-start;
}

    
}