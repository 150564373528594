.banner-wrapper {
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(3.06vw, 44px);
    position: relative;
}

.banner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-content {
    display: flex;
    align-items: center;
    gap: 24px;
    color: white;
}

.banner-text {
    font-family: "Assistant";
    color: inherit;
    font-weight: 400;
    font-size: var(--desktop-16px);
    transition: opacity ease 0.3s;
    
}

@media screen and (max-width: 478px) {

    .banner-wrapper {
        width: 100%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: min(10.14vw, 42px);
        position: relative;
    }
    
    .banner-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .banner-content {
        display: flex;
        align-items: center;
        gap: 4px;
        color: white;
    }
    
    .banner-text {
        font-family: "Assistant";
        color: inherit;
        font-weight: 400;
        font-size: min(3.38vw, 14px);
        transition: opacity ease 0.3s;
        
    }
    
    
    
    
}