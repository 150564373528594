.plan-wrapper {
    width: min(83.33vw, 1200px);
    height: min(26.04vw, 375px);
    box-sizing: border-box;
    border-radius: min(2.22vw, 32px);
    background-color: rgba(0, 0, 0, 0.3);
    padding-top: min(1.60vw, 23px);
    padding-left: min(1.60vw, 23px);
    transition: background-color ease-out 0.4s;
    cursor: pointer;
}

.plan-container {
    display: flex;
    gap: min(1.67vw, 24px);
}

.plan-column-1 {
    display: flex;
    flex-direction: column;
    gap: min(1.26vw, 18.1px);
    width: min(16.11vw, 232px);
}

.plan-logo-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.plan-title {
    font-family: 'Klein Condensed Trial';
    font-size: min(6.08vw, 87.59px);
    line-height: min(4.74vw, 68.32px);
    text-align: right;
    color: white;
    max-width: min(14.24vw, 205px);
    align-self: flex-end;
}

.plan-pill {
    height: min(1.68vw, 24.12px);
    padding-left: min(1.79vw, 25.72px);
    padding-right: min(1.79vw, 25.72px);;
    border-radius: min(3.35vw, 48.23px);
    background-color: rgba(50, 50, 50, 0.3);
    font-family: "Mont";
    color: white;
    font-size: min(0.89vw, 12.86px);
    line-height: min(1.07vw, 15.43px);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    
}

.plan-image-container {
    width: min(20.83vw, 300px);
    height: min(20.83vw, 300px);
    border-radius: min(1.67vw, 24px);
    overflow: hidden;
    object-fit: cover;    
    
}

.plan-logo-svg-wrapper {
    width: min(3.82vw, 55px);
    height: min(3.19vw, 46px);
    flex-shrink: 0;
}

.plan-cover-image {
  width: 100%;
  height: 100%;
}

.plan-cover-imagepos4id1 {
    width: 100%;
    height: 100%;
}

.plan-cover-imagepos1id2 {
    object-position: center;
}

.pos4 {
    
}

.plan-cover-image-container {
    width: min(20.83vw, 300px);
    height: min(20.83vw, 300px);
    object-fit: cover;
}

.plan-column-2 {
    padding-top: min(1.01vw, 14.5px);
}

.plan-column-3 {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.plan-description-text {
    font-size: min(1.12vw, 16.08px);
    line-height: min(1.34vw, 19.29px);
    font-family: "Mont";
    letter-spacing: -0.5px;
    color: white;
}

.plan-list li {
    max-width: min(20.42vw, 294px);
}

.plan-column-4 {
    display: flex;
    flex-direction: column;
    height: inherit;
    align-items: center;
    justify-content: space-between;
    padding-top: min(2.22vw, 32px);
    padding-bottom: min(2.22vw, 32px);
}

.plan-price-container {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    color: #B1B1B1;
    opacity: 0;
    transition: color ease 0.3s;
}

.plan-currency {
    font-family: "Barlow Condensed";
    font-size: min(2.22vw, 32px);
    font-weight: 700;
    
}

.plan-price {
    font-family: "Barlow Condensed";
    font-size: min(4.44vw, 64px);
    line-height: 92%;
    
    font-weight: 700;
    letter-spacing: -1px;
}

.know-more-button {
    height: min(3.47vw, 50px);
    padding-left: min(2.22vw, 32px);
    padding-right: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(6.94vw, 100px);
    border: 1px solid white;
    font-family: "Poppins";
    font-weight: 500;
    font-size: var(--desktop-16px);
    line-height: 100%;
    letter-spacing: -1px;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
    /*transition-delay: 0.3s;*/
}

.special-hover {
    transition-delay: 0s !important;
}

.special-hover:hover {
    background-color: white;
    color: black;
    
}

.button-arrow {
    width: min(3.47vw, 50px);
    height: min(3.47vw, 50px);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
    /*transition-delay: 0.3s;*/
}

.plan-button-row {
    display: flex;
    align-items: center;
    gap: 3px;
}

/*pop up*/

.background-overlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    transition: opacity ease 0.3s;
}

.poup-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.close-icon {
    width: fit-content;
    cursor: pointer;
    color: white;
    transition: color ease 0.3s;
}

.close-icon:hover {
    color: #BFFF78;
}

.popup-container {
    width: min(59.86vw, 862px);
    border-radius: min(2.22vw, 32px);
    background-color: #131417;
    box-sizing: border-box;
    padding-top: min(4.17vw, 60px);
    padding-left: min(4.17vw, 60px);
    padding-right: min(4.17vw, 60px);
    padding-bottom: min(10.12vw, 145.73px);
    display: flex;
    flex-direction: column;
    gap: min(2.78vw, 40px);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: min(45.21vw, 651px);
}

.popup-container button {
    width: 100%;
}

.popup-container::-webkit-scrollbar {
    display: none;
    width: 1px;
    height: 2px; /* Adjust the height for horizontal scrollbar */
  }

  .popup-container::-webkit-scrollbar-track {
    background: transparent;
  }

  .popup-container::-webkit-scrollbar-thumb {
    background-color: #BFFF78;
    border-radius: 10px; /* Rounded edges */
  }



.popup-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-logo {
    width: min(7.78vw, 112px);
    height: min(6.49vw, 93.47px);
}

.popup-title-container {
    display: flex;
    flex-direction: column;
    gap: min(0.62vw, 9px);
}

.popup-pill {
    height: min(3.27vw, 47.12px);
    padding-left: min(1.44vw, 20.74px);
    padding-right: min(1.44vw, 20.74px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Mont";
    color: white;
    font-size: min(1.75vw, 25.13px);
    line-height: min(2.09vw, 30.15px);
    letter-spacing: -1px;
    background-color: #3232324D;
    border-radius: min(6.54vw, 94.23px);
    width: fit-content;
}

.popup-title {
    font-family: "Klein Condensed Trial";
    font-weight: 800;
    color: white;
    font-size: min(6.08vw, 87.59px);
    letter-spacing: -1px;
}

.popup-splide-container {
    width: min(51.53vw, 742px);
    height: min(22.15vw, 319px);
    border-radius: min(2.22vw, 32px);
}

.slide-image {
    width: min(51.53vw, 742px);
    height: min(22.15vw, 319px);
    object-position: top;
    object-fit: cover;
    border-radius: min(2.22vw, 32px);
}

.popup-description-container {
    display: flex;
    flex-direction: column;
    gap: min(4.17vw, 60px);
}

.description-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.popup-description-title {
    font-family: "Mont-Bold";
    font-weight: 800;
    font-size: min(1.67vw, 24px);
    color: #BFFF78;
}

.row-list li {
    font-family: "Mont";
    font-weight: 600;
    font-size: min(1.67vw, 24px);
    color: #858585;
    margin-bottom: 5px;
}

/*infinity plans.js*/

.showcase-wrapper {
    width: min(83.33vw, 1200px);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.plan-showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: min(6.18vw, 89px);
    padding-bottom: min(6.94vw, 100px);
    z-index: 1;
}

.infinity-header-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: min(7.01vw, 101px);
}

.infinity-header-logo {
    width: min(30.28vw, 436px);
    height: auto;
}

.infinity-header-column {
    display: flex;
    flex-direction: column;
    
}

.infinity-header-title {
    max-width: min(44.17vw, 636px);
    word-wrap: break-word;
    font-size: min(1.67vw, 24px);
    line-height: min(2.83vw, 40.8px);
    color: #858585;
}

.infinity-plans-second-logo {
    margin-top: -2%;
    width: min(26.98vw, 388.63px);
    height: auto;
}

.button-margin {
    margin-top: min(1.34vw, 19.43px);
}

.special-button-padding {
    padding-left: min(4.72vw, 68px) !important;
    padding-right: min(4.72vw, 68px) !important;
}

.popup-arrows-container {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

.popup-arrow {
    color: white;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.popup-arrow:hover {
    background-color: #BFFF78;
    color: black;
    border-color: #BFFF78;
}

@media screen and (max-width: 478px) {

    .popup-arrow {
        width: 35px !important;
        height: 35px !important;
    }

    .popup-arrow:hover {
        background-color: transparent;
        color: white;
        border-color: white;
    }

    .special-button-padding {
        padding-left: min(14.49vw, 60px) !important;
        padding-right: min(14.49vw, 60px) !important;
    }

    .button-margin {
        margin-top: min(4.83vw, 20px);
    }

    .infinity-plans-second-logo {
        width: min(58.45vw, 242px);
        height: auto;
    }

    .splide__list {
        width: 100% !important;
        position: static;
    }

    .mobile-plan-background-container {
        width: 400px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10px;
        z-index: -1;
        filter: brightness(0.5);
    }

    .mobile-bottom-price-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(5vw, 21px);
    }

    .mobile-whats-included-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .mobile-whats-included-text {
        color: #BFFF78;
        font-family: "Mont-Bold";
        font-size: min(3.8vw, 16px);
    }

    .mobile-plan-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min(12vw, 50px);
        height: min(12vw, 50px);
        border-radius: 100%;
        border: 1px solid #BFFF78;
        color: #BFFF78;
    }

    .plan-wrapper {
        position: relative;
        width: min(79.95vw, 331px);
        height: min(122.6vw, 507px);
        box-sizing: border-box;
        border-radius: min(7.72vw, 32px);
        background-color: #0A0B0D;
        padding-top: min(7.7vw, 32px);
        padding-left: min(1.60vw, 23px);
        transition: background-color ease-out 0.4s;
        cursor: pointer;
        z-index: 0;
        overflow: hidden;
    }
    
    .plan-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(1.67vw, 24px);
        z-index: 1;
    }
    
    .plan-column-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: min(1.26vw, 18.1px);
        width: 100%;
    }
    
    .plan-logo-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: min(5.7vw, 24px);
        
    }
    
    .plan-title {
        font-family: 'Klein Condensed Trial';
        font-size: min(21.1vw, 87.6px);
        line-height: min(16.5vw, 68.32px);
        text-align: center;
        color: white;
        width: fit-content;
        max-width: none;
        align-self: center;
        margin-top: min(5vw, 21px);
    }
    
    .plan-pill {
        height: min(5.82vw, 24.12px);
        padding-left: min(6.21vw, 25.72px);
        padding-right: min(6.21vw, 25.72px);;
        border-radius: min(11.6vw, 48.23px);
        background-color: rgba(50, 50, 50, 0.3);
        font-family: "Mont";
        color: white;
        font-size: min(3.1vw, 12.86px);
        line-height: min(3.72vw, 15.43px);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        white-space: nowrap;
        
    }
    
    .plan-image-container {
        width: min(20.83vw, 300px);
        height: min(20.83vw, 300px);
        border-radius: min(1.67vw, 24px);
        overflow: hidden;
        object-fit: cover;
        
    }
    
    .plan-logo-svg-wrapper {
        width: min(9.4vw, 32px);
        height: min(7.8vw, 32.55px);
        flex-shrink: 0;
    }
    
    .plan-cover-image {
        width: 100%;
        height: 100%;
    }
    
    .plan-column-2 {
        padding-top: min(1.01vw, 14.5px);
    }
    
    .plan-column-3 {
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .plan-description-text {
        font-size: min(1.12vw, 16.08px);
        line-height: min(1.34vw, 19.29px);
        font-family: "Mont";
        letter-spacing: -0.5px;
        color: white;
    }
    
    .plan-list li {
        max-width: min(20.42vw, 294px);
    }
    
    .plan-column-4 {
        display: flex;
        flex-direction: column;
        height: inherit;
        align-items: center;
        justify-content: space-between;
        padding-top: min(2.22vw, 32px);
        padding-bottom: min(2.22vw, 32px);
    }
    
    .plan-price-container {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        color: #B1B1B1;
        opacity: 0;
        transition: color ease 0.3s;
    }
    
    .plan-currency {
        font-family: "Barlow Condensed";
        font-size: min(2.22vw, 32px);
        font-weight: 700;
        
    }
    
    .plan-price {
        font-family: "Barlow Condensed";
        font-size: min(7.72vw, 32px);
        line-height: 100%;
        font-weight: 700;
        letter-spacing: -1px;
    }
    
    .know-more-button {
        height: min(10.1vw, 42px);
        padding-left: min(7.3vw, 30.5px);
        padding-right: min(7.3vw, 30.5px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: min(6.94vw, 100px);
        border: 1px solid white;
        font-family: "Poppins";
        font-weight: 500;
        font-size: min(3.38vw, 14px);
        line-height: 100%;
        letter-spacing: -1px;
        color: white;
        cursor: pointer;
        box-sizing: border-box;
        transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
        /*transition-delay: 0.3s;*/
    }
    
    .special-hover {
        transition-delay: 0s !important;
        
    }
    
    .special-hover:hover {
        background-color: white;
        color: black;
        
    }
    
    .button-arrow {
        width: min(3.47vw, 50px);
        height: min(3.47vw, 50px);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
        /*transition-delay: 0.3s;*/
    }
    
    .plan-button-row {
        display: flex;
        align-items: center;
        gap: 3px;
    }
    
    /*pop up*/
    
    .background-overlay {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        opacity: 0;
        transition: opacity ease 0.3s;
    }
    
    .poup-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .close-icon-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    .close-icon {
        width: fit-content;
        cursor: pointer;
        color: white;
        transition: color ease 0.3s;
    }
    
    .close-icon:hover {
        color: #BFFF78;
    }
    
    .popup-container {
        width: min(92.2vw, 382px);
        border-radius: min(2.22vw, 32px);
        background-color: #131417;
        box-sizing: border-box;
        padding-top: min(6vw, 25px);
        padding-left: min(4.17vw, 60px);
        padding-right: min(4.17vw, 60px);
        padding-bottom: min(35vw, 115.73px);
        display: flex;
        flex-direction: column;
        gap: min(9.66vw, 40px);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: min(150.9vw, 625px);
    }
    
    .popup-container button {
        width: 100%;
    }
    
    .popup-container::-webkit-scrollbar {
        display: none;
        width: 1px;
        height: 2px; /* Adjust the height for horizontal scrollbar */
      }
    
      .popup-container::-webkit-scrollbar-track {
        background: transparent;
      }
    
      .popup-container::-webkit-scrollbar-thumb {
        background-color: #BFFF78;
        border-radius: 10px; /* Rounded edges */
      }
    
    
    
    .popup-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .popup-logo {
        width: min(7.78vw, 112px);
        height: min(6.49vw, 93.47px);
    }
    
    .popup-title-container {
        display: flex;
        flex-direction: column;
        gap: min(2.78vw, 40px);
    }
    
    .popup-pill {
        height: min(3.27vw, 47.12px);
        padding-left: min(1.44vw, 20.74px);
        padding-right: min(1.44vw, 20.74px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Mont";
        color: white;
        font-size: min(1.75vw, 25.13px);
        line-height: min(2.09vw, 30.15px);
        letter-spacing: -1px;
        background-color: #3232324D;
        border-radius: min(6.54vw, 94.23px);
        width: fit-content;
    }
    
    .popup-title {
        font-family: "Klein Condensed Trial";
        font-weight: 800;
        color: white;
        font-size: min(12vw, 49.98px);
        line-height: 100%;
        letter-spacing: -1px;
    }
    
    .popup-splide-container {
        width: min(78.7vw, 326px);
        height: min(77.2vw, 320px);
        border-radius: min(2.22vw, 32px);
        align-self: center;
    }
    
    .slide-image {
        width: min(78.7vw, 326px);
        height: min(77.2vw, 320px);
        object-position: top;
        object-fit: cover;
        border-radius: min(2.22vw, 32px);
    }
    
    .popup-description-container {
        display: flex;
        flex-direction: column;
        gap: min(4.17vw, 60px);
    }
    
    .description-row {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .popup-description-title {
        font-family: "Mont-Bold";
        font-weight: 800;
        font-size: min(3.38vw, 14px);
        color: #BFFF78;
    }
    
    .row-list li {
        font-family: "Mont";
        font-weight: 600;
        font-size: min(3.38vw, 14px);
        color: #858585;
        margin-bottom: 5px;
    }
    
    /*infinity plans.js*/
    
    .showcase-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }
    
    .background-container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
    }
    
    .plan-showcase-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: min(6.18vw, 89px);
        padding-bottom: min(6.94vw, 100px);
        z-index: 1;
        overflow: visible;
    }
    
    .infinity-header-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: min(7.97vw, 33px);
    }
    
    .infinity-header-logo {
        width: min(59.9vw, 248px);
        height: auto;
    }
    
    .infinity-header-column {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .infinity-header-title {
        max-width: min(44.17vw, 636px);
        word-wrap: break-word;
        font-size: min(1.67vw, 24px);
        line-height: min(2.83vw, 40.8px);
        color: #858585;
    }
    
}