
.gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(100vw, 1440px);
    padding-bottom: min(2.63vw, 38px);
}

.gallery-header-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    gap: min(4.41vw, 53px);
    justify-content: space-between;
    overflow: hidden;
}

.gallery-splide-wrapper {
    display: flex;
    align-items: center;
    gap: min(1.75vw, 21px);
    margin-top: -15%;
}

.gallery-splide-container {
    width: min(83vw, 1200px);
    height: min(35.9vw, 517px);
    overflow: hidden;


}

.gallery-center-column {
    width: min(83vw, 1200px);
    display: flex;
    flex-direction: column;
    gap: min(3.16vw, 38px);
}

.arrow-column {
    height: min(43vw, 517px);
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.arrow-wrapper {
    width: min(4.16vw, 50px);
    height: min(4.16vw, 50px);
    border-radius: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.arrow-wrapper:hover {
    background-color: #BFFF78;
    color: black;
    border-color: transparent;
}

.inner-arrow-wrapper {
    width: min(0.91vw, 11px);
    height: auto;
}

.gallery-slide {
    width: min(83vw, 1200px);
    border-radius: min(4.44vw, 64px);
    object-fit: cover;   
}

.gallery-slide-container {
    width: min(83vw, 1200px);
    height: min(35.9vw, 517px);
    border-radius: min(4.44vw, 64px);
    object-fit: cover;
    overflow: hidden;   
}

.icon-wrapper {
    width: min(9.3vw, 134px);
    height: min(3.26vw, 47px);
}

@media screen and (max-width: 478px) {

    
.icon-wrapper {
    width: min(72.4vw, 300px);
    height: auto;
}

.gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(100vw, 414px);
    padding-bottom: min(12.1vw, 50px);
}

.gallery-header-container {
    width: auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    gap: min(4.41vw, 53px);
    justify-content: space-between;
    overflow: hidden;
}

.gallery-splide-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(5.55vw, 23px);
    margin-top: -65%;
}

.gallery-splide-container {
    width: min(92.2vw, 382px);
    height: min(127.53vw, 527.98px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.gallery-center-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(5.55vw, 23px);
}

.arrow-column {
    height: auto;
    align-self: center;
    display: flex;
    align-items: center;
    gap: min(2vw, 8.67px);
}

.arrow-wrapper {
    width: min(13vw, 54.2px);
    height: min(13vw, 54.2px);
    border-radius: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.arrow-wrapper:hover {
    background-color: inherit;
    color: inherit;
    border-color: inherit;
}

.inner-arrow-wrapper {
    width: min(2.87vw, 11.92px);
    height: auto;
}

.gallery-slide {
    width: min(92.2vw, 382px);
    height: min(127.5vw, 527.98px);
    border-radius: 10%;
    object-fit: cover;
}

.gallery-slide-container {
    width: min(92.2vw, 382px);
    height: min(127.5vw, 527.98px);
    border-radius: 10%;
    object-fit: cover;
    overflow: hidden;
}

.custom-position {
    object-position: 80%;
}

.custom-position-2 {
    object-position: 40%;
}

    
}
