body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --desktop-16px: min(1.11vw, 16px);
}

@font-face {
  font-family: "Mont";
  src: url('./assets/fonts/Mont-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Mont-Bold";
  src: url('./assets/fonts/Mont/Mont-Bold.otf');
  font-weight: 800;
}

@font-face {
  font-family: "Archivo-Expanded";
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-Regular.ttf');
}


.centering-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.max-width-container {
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
}

.max-width-container-1200 {
  width: 100%;
  max-width: 1200px;
  overflow: hidden;

}

.max-width-container-1440 {
  width: 100%;
  max-width: 1440px;
}


.hide-desktop {
  display: none !important;
}

.black-line {
  width: 100%;
  height: 0.32px;
  background-color: black;
}

@media screen and (max-width: 478px) {

  .hide {
    display: none !important;
  }

  .max-width-container-1200,
  .max-width-container {
    max-width: min(92.2vw, 382px);
  }

  .hide-desktop {
    display: flex !important;
  }


  
}




