

.instagram-row-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(4.16vw, 60px);
    margin-top: min(7vw, 100.95px);
}

.instagram-tag {
    font-family: "Barlow";
    color: #8F8F8F;
    font-size: min(2.22vw, 32px);
    font-weight: 600;
}

.instagram-header-container {
    width: min(71.94vw, 1036px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(1.45vw, 21px);
}

.white-line {
    width: 100%;
    height: 1px;
    background-color: white;
}

.insta-post {
    width: min(22.5vw, 325px);
    height: min(28.1vw, 406px);
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
}

.instagram-posts-row {
    display: flex;
    overflow: hidden;
    gap: 5px;
}

@media screen and (max-width: 478px) {

    

.instagram-row-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(8.9vw, 37px);
    margin-top: min(30.6vw, 127px);
}

.instagram-tag {
    font-family: "Barlow";
    color: #8F8F8F;
    font-size: min(3.38vw, 16px);
    font-weight: 600;
}

.instagram-header-container {
    width: min(71.94vw, 1036px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(1.45vw, 21px);
}

.white-line {
    width: 100%;
    height: 1px;
    background-color: white;
}

.insta-post {
    width: min(33.3vw, 138px);
    height: auto;
}

.instagram-posts-row {
    display: flex;
    overflow: hidden;
}
    
}