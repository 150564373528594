
.loading-screen {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color ease 0.3s;
  animation: colorOscillation 1s infinite;
  z-index: 99999999;
  
}

.loading-wrapper {
  
  opacity: 1;
  transition: opacity ease 1s;
  z-index: 999;
  visibility: visible;
  
}

@keyframes colorOscillation {
  0%, 100% {
    color: white;
  }
  50% {
    color: #BFFF78;
  }
}

.target {
  opacity: 1;
  transition: opacity ease 0.3s;
}

.cover-wrapper {
  width: 100vw;
  height: min(56.39vw, 812px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background-image: url('../../assets/landing/video_placeholder.webp');*/
  background-size: cover;
  background-color: black;
  z-index: -2;
}

.cover-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  filter: brightness(0.7);
  opacity: 1;
  /*transition: opacity ease 0.3s, filter ease 0.5s;*/
}

.cover-background video {
  width: 100%;
  
}

.cover-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: top;
  
}

.cover-text-container {
  justify-self: flex-start;
  align-self: flex-start;
  margin-top: min(26.94vw, 388px);
  height: min(20.83vw, 300px);
  width: min(62.78vw, 904px);
}

.cover-text-first-row {

}

.at-infinity-wrapper {
  width: 100%;
  height: min(34.72vw, 500px);
  display: flex;
}

.at-infinity-column {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.justify-right {
  justify-content: flex-end;
  padding-right: min(9.03vw, 130px);
}

.justify-left {
  justify-content: flex-start;
  padding-left: min(9.03vw, 130px);
}

.left-column-text {
  font-family: "Klein Condensed Trial";
  font-weight: 800;
  color: black;
  font-size: min(4.17vw, 60px);
  line-height: min(3.88vw, 55.8px);
  text-transform: uppercase;
  letter-spacing: -1.5px;
}

.right-column-text {
  font-family: "Mont";
  font-size: min(2.22vw, 32px);
  color: #858585;
  line-height: min(3.22vw, 46.4px);
  max-width: min(33.40vw, 481px);
  word-wrap: break-word;
}


.partnering-wrapper {
  width: 100%;
  height:  min(56.74vw, 817px);
  background-color: #BFFF78;
  margin-top: max(-22.33vw, -330px);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnering-text {
  width: min(69.44vw, 1000px);
  font-family: "Mont";
  font-size: min(2.22vw, 32px);
  line-height: min(2.66vw, 38.4px);
  text-align: center;
}

.dark-mode-wrapper {
  width: 100%;
  background-color: rgba(19, 20, 23, 1);
  background-image: url('../../assets/landing/textura-inf.webp');
  /*background-image: url('../../assets/landing/texture-final-light.webp'); lightweight solution*/
  background-repeat: repeat;
  
  position: relative;
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.calendly-overlay {
  backdrop-filter: blur(10px) brightness(1);
}

/*
.calendly-popup-close {
  top: 75px !important;
  right: 195px !important;
}
*/

@media screen and (min-width: 1025px) {
  .calendly-popup-content {
    height: 750px !important;
    max-height: 750px !important;
  }  
}

@media screen and (max-width: 478px) {

  
.loading-screen {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color ease 0.3s;
  animation: colorOscillation 1s infinite;
  z-index: 99999999;
  
}

.loading-wrapper {
  
  opacity: 1;
  transition: opacity ease 1s;
  z-index: 999;
  visibility: visible;
  
}

@keyframes colorOscillation {
  0%, 100% {
    color: white;
  }
  50% {
    color: #BFFF78;
  }
}

.target {
  opacity: 1;
  transition: opacity ease 0.3s;
}

.cover-wrapper {
  width: 100vw;
  height: min(161.83vw, 670px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background-image: url('../../assets/landing/compressed_bg.mp4');*/
  /*background-position: center;*/
  /*background-color: transparent;*/
  z-index: -2;
}

.cover-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  filter: brightness(0.7);
  opacity: 0;
  /*background-image: url('../../assets/landing/compressed_bg.mp4');*/
  /*background-position: center;*/
  transition: opacity ease 0s, filter ease 0s;
}

.cover-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  
}

.cover-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: top;
  
}

.cover-text-container {
  justify-self: flex-start;
  align-self: flex-start;
  margin-top: min(106vw, 440px);
  width: auto;
  height: min(36.23vw, 150px);

}

.cover-text-first-row {

}

.at-infinity-wrapper {
  width: 100%;
  height: min(171vw, 712px);
  display: flex;
  flex-direction: column;
}

.at-infinity-column {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: min(3.86vw, 16px);
}

.justify-right {
  justify-content: inherit;
  padding-right: 0px;
}

.justify-left {
  justify-content: inherit;
  
}

.left-column-text {
  font-family: "Klein Condensed Trial";
  font-weight: 800;
  color: black;
  font-size: min(10.1vw, 42px);
  line-height: min(9.43vw, 39px);
  text-transform: uppercase;
  letter-spacing: -1.5px;
}

.right-column-text {
  font-family: "Mont";
  font-size: min(5.79vw, 23px);
  color: #858585;
  line-height: min(8.4vw, 34.8px);
  max-width: min(86.47vw, 358px);
  word-wrap: break-word;
}


.partnering-wrapper {
  width: 100%;
  height:  min(232vw, 962px);
  background-color: #BFFF78;
  margin-top: -60%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.partnering-text {
  width: 95%;
  height: auto;
  font-family: "Mont";
  font-size: min(5.79vw, 24px);
  line-height: min(6.95vw, 28.8px);
  text-align: center;
}

.dark-mode-wrapper {
  width: 100%;
  background-color: rgba(19, 20, 23, 1);
  background-image: url('../../assets/landing/textura-inf.webp');
  /*background-image: url('../../assets/landing/texture-final-light.webp'); lightweight solution*/
  background-repeat: repeat;
  
  position: relative;
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.calendly-overlay {
  backdrop-filter: blur(10px) brightness(1);
}

/*
.calendly-popup-close {
  top: 75px !important;
  right: 195px !important;
}
*/
  
}


