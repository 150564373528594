

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 8px;
    row-gap: 60px;
    padding-top: 70px;
}

.grid-item {
    width: min(6.9vw, 100px);
    height: 100px;
    background-color: red;
}

/*filter bar*/

.filter-bar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(2.29vw, 33px);
    background-color: #2C935E;
    padding-top: min(0.55vw, 8.05px);
    padding-right: min(0.69vw, 10px);
    padding-bottom: min(0.55vw, 8.05px);
    padding-left: min(0.69vw, 10px);
    border-radius: min(6.94vw, 100px);
    
}

.filter-buttons-container {
    display: flex;
    gap: min(0.55vw, 8px);
    align-items: center;
}

.filter-button {
    padding: min(1.38vw, 20px);
    border-radius: min(3.47vw, 50px);
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(0.97vw, 14px);
    line-height: 100%;
    color: white;
    transition: all ease 0.5s;
    cursor: pointer;
    text-transform: uppercase;
}

.filter-button:hover {
    background-color: #216E47;
}

.search-container {
    position: relative;
    
}

.filter-search-bar {
    position: relative;
    all: initial;
    border: 1px solid white;
    width: min(5.27vw, 76px);
    height: min(4.1vw, 59.1px);
    padding-left: min(1.38vw, 20px);
    padding-right: min(3.47vw, 50px);
    border-radius: min(6.9vw, 100px);
    color: white;
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(0.97vw, 14px);
    line-height: 100%;
   
}

.filter-search-bar::placeholder {
    color: white;
}

.search-icon {
    width: min(1.23vw, 17.85px);
    height: auto;
  

}

.search-icon-div {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    z-index: 0;
    border: 1px solid transparent;
    width: min(8.75vw, 126px);
    height: min(4.1vw, 59.1px);
    border-radius: min(6.9vw, 100px);
}

.sloped-top {
    clip-path: polygon(100% 100%, 100% 0%, 0% 2%, 0 100%);
    -webkit-clip-path: polygon(100% 100%, 100% 0%, 0% 2%, 0 100%); 
}

/*/filter bar*/

.cut-top {
    border-left: 20px;
    border-right: 100px;
}

.centering-overflow {
    overflow: hidden;
}

@media screen and (max-width: 478px) {

.centering-overflow {
    overflow: visible;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: min(0.72vw, 3px);
    row-gap: min(4.85vw, 20px);
    padding-top: 70px;
}

.grid-item {
    width: min(6.9vw, 100px);
    height: 100px;
    background-color: red;
}

/*filter bar*/

.filter-bar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: min(2.29vw, 33px);
    background-color: #2C935E;
    padding-top: 5px;
    padding-right: 6px;
    padding-bottom: 5px;
    padding-left: 6px;
    border-radius: 50px;
    position: relative;
    
    
}

.filter-bar-gastronomia-mobile {
    width: min(91vw, 378px) !important;
}

.filter-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: initial;
    position: absolute;
    top: 110%;
    z-index: 999;
    width: 100%;
    backdrop-filter: blur(1px);
    
}

.filter-button {
    padding-top: min(2.91vw, 12px);
    padding-right: min(5.58vw, 23px);
    padding-bottom: min(2.91vw, 12px);
    padding-left: min(5.58vw, 23px);
    
    border-radius: min(24.27vw, 100px);
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(3.39vw, 14px);
    line-height: min(5.82vw, 24px);

    color: white;
    transition: all ease 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #216E47;
    align-self: flex-start;
}

.filter-button:hover {
    background-color: #216E47;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    
    
}

.filter-search-bar {
    position: relative;
    all: initial;
    border: 1px solid white;
    width: min(36.16vw, 149px);
    height: min(11.89vw, 49px);
    padding-left: min(5.58vw, 23px);
    padding-right: min(10.92vw, 45px);
    border-radius: min(6.9vw, 100px);
    color: white;
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(3.39vw, 14px);
    line-height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

   
}

.filter-search-bar::placeholder {
    color: white;
}

.search-icon {
    width: 19px;
    height: auto;
    margin-right: 23px;

}

.search-icon-div {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: initial;
    z-index: initial;
    border: 1px solid transparent;
    width: 100%;
    height: auto;
    border-radius: min(6.9vw, 100px);
    
  
}

.sloped-top {
    clip-path: polygon(100% 100%, 100% 0%, 0% 1%, 0 100%);
    -webkit-clip-path: polygon(100% 100%, 100% 0%, 0% 1%, 0 100%); 
}

/*/filter bar*/

.cut-top {
    border-left: 20px;
    border-right: 100px;
}




    
}