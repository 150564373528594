
.footer-wrapper {
    width: 100%;
    max-width: 1440px;
    padding-left: min(3.75vw, 45px);
    padding-right: min(3.75vw, 45px);
    padding-top: min(1.5vw, 18px);
    padding-bottom: min(7vw, 84px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: min(2.91vw, 35px);
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(1.5vw, 18px);
}

.footer-first-row {
    width: 100%;
    height: min(23.5vw, 282px);
    display: flex;
    align-items: center;
    
}

.footer-center-column {
    width: min(56.56vw, 678.75px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-side-column {
    width: min(27.81vw, 333.75px);
    height: 100%;
    display: flex;
    align-items: center;
}

.footer-logo-wrapper {
    width: auto;
    height: min(5.85vw, 70.2px);
}

.footer-caption-wrapper {
    display: flex;
    align-items: center;
    gap: min(3.7vw, 44.45px);
}

.footer-caption-svg {
    width: min(40.16vw, 482px);
    height: min(10.66vw, 128px);
}

.socials-column {
    display: flex;
    flex-direction: column;
    gap: min(2vw, 24.77px);
}

.socials-logo {
    width: min(1.9vw, 23.85px);
    height: min(1.9vw, 23.85px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.socials-logo:hover {
    opacity: 0.7;
}

.footer-book-button {
    height: min(4.87vw, 58.54px);
    padding-left: min(6.39vw, 76.71px);
    padding-right: min(6.39vw, 76.71px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: min(3.75vw, 45.03px);
    border: 1.5px solid #BFFF78;
    color: white;
    font-family: "Mont";
    font-size: min(1.5vw, 18.01px);
    line-height: 100%;
    letter-spacing: -1px;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.footer-book-button:hover {
    background-color: white;
    color: #131417;
    border-color: transparent;
}

.side-column-right-justify {
    justify-content: flex-end;
}

.footer-link-wrapper {
    width: 100%;
    cursor: pointer;
}

.footer-link-container {
    display: flex;
    flex-direction: column;
    gap: min(2.5vw, 30px);
}

.footer-link-title {
    font-family: "Barlow";
    color: white;
    font-size: min(1.5vw, 18px);
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    transition: color ease 0.3s;
}

.footer-link-arrow {
    width: min(4.5vw, 54px);
    height: min(4.5vw, 54px);
    border: 0.75px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    transition: margin-left ease-in-out 0.4s, background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.footer-link-arrow:hover {
    background-color: #BFFF78;
    color: #131417;
    border-color: transparent;
}

.footer-row {
    height: min(10.31vw, 123.75px);
    display: flex;

}

.center-column-link-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: min(3.91vw, 47px);
}

.right-container {
    width: 100%;
    
    display: flex;
    
}

.right-padding {
    
    width: 100%;
    padding-left: min(3.91vw, 47px);
}

.back-to-top-button {
    display: flex;
    align-items: center;
    align-self: flex-end;
    transform: rotate(270deg);
    gap: 10px;
    height: min(11.1vw, 134px);
    position: absolute;
    top: 0;
    right: 0;
}

.back-to-top-title {
    color: white;
    font-family: "Mont";
    font-size: min(1.16vw, 14px);
    line-height: 100%;
}

.back-to-top-button-container {
    all: inherit;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 478px) {


.back-to-top-button-container {
    position: absolute;
    top: -10%;
    
}

.mobile-footer-caption {
    font-family: "Klein Condensed Trial";
    color: #BFFF78;
    font-weight: 800;
    max-width: 138px;
    font-size: min(7.7vw, 32px);
    line-height: 90%;
    text-align: center;
    word-wrap: break-word;
}

.mobile-margin {
    margin-top: min(6.43vw, 26.63px);
}

.mobile-contact-button {
    background-color: #BFFF78;
    color: black !important;
}

.mobile-bottom-links-container {
    margin-top: min(6.43vw, 26.63px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(6.43vw, 26.63px);

}

.mobile-socials-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(5vw, 21px);
}

.mobile-email-info {
    color: white;
    font-family: "Mont";
    font-size: min(3.8vw, 16px);

}

.mobile-bottom-copyright {
    font-family: "Mont";
    color: #DEDEDE;
    font-size: min(1.99vw, 8.26px);
}
    
.footer-wrapper {
    width: 100%;
    max-width: 1440px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: min(2.91vw, 35px);
    padding-bottom: min(17.3vw, 80px);
    position: relative;
    overflow: visible;
    margin-top: min(25.8vw, 107px);
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(1.5vw, 18px);
}

.footer-first-row {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.footer-center-column {
    width: min(56.56vw, 678.75px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-side-column {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(6.4vw, 26.6px);
}

.footer-logo-wrapper {
    width: auto;
    height: min(15.9vw, 66px);
}

.footer-caption-wrapper {
    display: flex;
    align-items: center;
    gap: min(3.7vw, 44.45px);
}

.footer-caption-svg {
    width: min(40.16vw, 482px);
    height: min(10.66vw, 128px);
}

.socials-column {
    display: flex;
    flex-direction: column;
    gap: min(2vw, 24.77px);
}

.socials-logo {
    width: min(5vw, 21.09px);
    height: min(5vw, 21.09px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.socials-logo:hover {
    opacity: 0.7;
}

.footer-book-button {
    height: min(12.5vw, 51.77px);
    padding-left: min(13.8vw, 57.36px);
    padding-right: min(13.8vw, 57.36px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: min(9.61vw, 39.82px);
    border: 1.5px solid #BFFF78;
    color: white;
    font-family: "Mont";
    font-size: min(3.38vw, 14px);
    line-height: 100%;
    letter-spacing: -1px;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.footer-book-button:hover {
    background-color: white;
    color: #131417;
    border-color: transparent;
}

.side-column-right-justify {
    justify-content: flex-end;
}

.footer-link-wrapper {
    width: 100%;
    cursor: pointer;
}

.footer-link-container {
    display: flex;
    flex-direction: column;
    gap: min(2.5vw, 30px);
}

.footer-link-title {
    font-family: "Barlow";
    color: white;
    font-size: min(1.5vw, 18px);
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    transition: color ease 0.3s;
}

.footer-link-arrow {
    width: min(12vw, 50px);
    height: min(12vw, 50px);
    border: 0.75px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    transition: margin-left ease-in-out 0.4s, background-color ease 0.3s, color ease 0.3s, border-color ease 0.3s;
}

.footer-link-arrow:hover {
    background-color: #BFFF78;
    color: #131417;
    border-color: transparent;
}

.footer-row {
    height: min(10.31vw, 123.75px);
    display: flex;

}

.center-column-link-row {
    display: flex;
    width: 100%;
    
    padding-left: min(3.91vw, 47px);
}

.right-container {
    width: 100%;
    
    display: flex;
    
}

.right-padding {
    
    width: 100%;
    padding-left: min(3.91vw, 47px);
}

.back-to-top-button {
    display: flex;
    align-items: center;
    align-self: flex-end;
    transform: rotate(270deg);
    gap: 10px;
    height: min(11.1vw, 134px);
    position: absolute;
    top: 0;
    z-index: 1;
}

.back-to-top-title {
    color: white;
    font-family: "Mont";
    font-size: min(3.38vw, 14px);
    line-height: 100%;
}
    
}