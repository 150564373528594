.faqs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    position: relative;
    z-index: 1;
    width: fit-content;
}

.faqs-title-bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.faqs-title {
    font-family: 'Klein Condensed Trial';
    font-size: min(4.8vw, 70px);
    line-height: 100%;
    color: white;
    letter-spacing: -1px;
    font-weight: 700;
}

.filter-bar-wrapper {
    padding-top: min(0.69vw, 10px);
    padding-bottom: min(0.69vw, 10px);
}
/*redundant*/
.faq-button {
    padding: min(1.38vw, 20px);
    border-radius: min(3.47vw, 50px);
    font-family: 'Mont';
    font-size: min(0.97vw, 14px);
    line-height: 100%;
    color: white;
    transition: all ease 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
}

.faq-button:hover {
    background-color: black;
}

.faqs-container {
    margin-top: min(2.5vw, 36px);
    display: flex;
    flex-direction: column;
    gap: 4px;
    /*fixed height to avoid faq compacts? or implement soft transition*/   
}

/*faq-row*/

.faq-row-container {
    width: min(57vw, 821px);
    height: auto;    
    padding-left: min(2.77vw, 40px);
    padding-right: min(2.77vw, 40px);
    background-color: #34373F;
    font-family: 'Mont';
    font-size: min(0.97vw, 14px);
    line-height: min(1.06vw, 15.32px);
    color: white;
    border-radius: 20px;
    overflow: hidden;
    font-weight: 600;
    border-radius: var(--desktop-16px);
    transition: background-color ease 0.5s, opacity ease 0.5s;

}

.faq-row-container:hover {
    background-color: black;
}


.faq-row-header-container {
    width: 100%;
    height: min(5.4vw, 77.8px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    transition: background-color ease 0.5s;
}



.faq-row-description-container {
    
    
}

.faq-row-description-container {
    opacity: 1;
    /*transition: max-height 0.6s ease-in-out, opacity 0.6s ease-in-out;*/
    transition: all ease 0.5s;
    
}

.faq-row-description {
    line-height: 1.5rem;
    padding-right: 40px;
    padding-bottom: 30px;
}

.load-more-faqs-container {
    display: flex;
    align-self: flex-start;
    color: white;
    gap: min(0.69vw, 10px);
    padding-left: min(2.77vw, 40px);
    margin-top: min(3.19vw, 46px);
    align-items: center;
    cursor: pointer;
    transition: opacity ease 0.5s;
}

.load-more-faqs-container:hover {
    opacity: 0.5;
}

.load-more-text {
    font-size: min(1.11vw, 16px);
    line-height: 100%;
    font-family: 'Mont';
    letter-spacing: -1px;
}

.load-more-icon {
    width: min(1.09vw, 15.81px);
    height: auto;
}

.faq-close-icon {
    width: min(1.31vw, 18px);
    height: auto;
}

@media screen and (max-width: 478px) {

    .faq-close-icon {
        width: min(2.41vw, 10px);
        height: auto;
    }

    .faqs-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: min(31.55vw, 130px) !important;
    }
    
    .faqs-title-bar-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
    .faqs-title {
        font-family: 'Klein Condensed Trial';
        font-size: min(13vw, 54px);
        line-height: 100%;
        color: white;
        letter-spacing: -2px;
    }
    
    .filter-bar-wrapper {
        width: min(83.46vw, 343.89px);
        height: min(14.47vw, 59.63px);
        margin-top: min(8.7vw, 36px) !important;
        padding-bottom: min(0.69vw, 10px);
        position: relative;
    }

        
    .filter-buttons-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 5px;
        
    }
    
    /*redundant*/
    .faq-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        height: 65px;
        border-radius: min(3.47vw, 50px);
        font-family: 'Mont';
        font-size: min(3.39vw, 14px);
        line-height: 100%;
        color: white;
        transition: all ease 0.5s;
        cursor: pointer;
        text-transform: uppercase;
        background-color: #34373F;
    }

    .selected-type-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 7vw;
        padding-right: 7vw;
    }

    .selected-text {
        font-family: 'Mont';
        font-size: min(3.39vw, 14px);
        line-height: 100%;
        color: white;
        text-transform: uppercase;
    }
    
    .faq-button:hover {
        background-color: black;
    }
    
    .faqs-container {
        margin-top: min(2.5vw, 36px);
        display: flex;
        flex-direction: column;
        gap: 4px;
        /*fixed height to avoid faq compacts? or implement soft transition*/   
    }
    
    /*faq-row*/
    
    .faq-row-container {
        width: min(83.49vw, 344px);
        height: auto;    
        padding-left: min(4vw, 16.66px);
        padding-right: min(2.77vw, 40px);
        background-color: #34373F;
        font-family: 'Mont';
        font-size: min(3.39vw, 14px);
        line-height: min(4.06vw, 16.8px);
        color: white;
        border-radius: 6.6px;
        overflow: hidden;
        transition: background-color ease 0.5s, opacity ease 0.5s;
    
    }
    
    .faq-row-container:hover {
        background-color: black;
    }
    
    
    .faq-row-header-container {
        width: 100%;
        height: min(19vw, 79px);
        border-radius: min(3.8vw, 16px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: background-color ease 0.5s;
    }

    .faq-row-header-container-text {
        word-wrap: break-word;
        max-width: 280px;
    }
    
    
    
    .faq-row-description-container {
        
        
    }
    
    .faq-row-description-container {
        opacity: 1;
        /*transition: max-height 0.6s ease-in-out, opacity 0.6s ease-in-out;*/
        transition: all ease 0.5s;
        
    }
    
    .faq-row-description {
        line-height: 1.5rem;
        padding-right: 40px;
        padding-bottom: 30px;
    }
    
    .load-more-faqs-container {
        display: flex;
        align-self: flex-start;
        color: white;
        gap: 9px;
        padding-left: min(2.77vw, 40px);
        margin-top: min(3.19vw, 46px);
        align-items: center;
        cursor: pointer;
        transition: opacity ease 0.5s;
        align-items: center;
        justify-content: center;
    }
    
    .load-more-faqs-container:hover {
        opacity: 0.5;
    }
    
    .load-more-text {
        font-size: min(3.39vw, 14px);
        line-height: 100%;
        font-family: 'Mont';
        letter-spacing: -1px;
    }
    
    .load-more-icon {
        height: 8px;
        width: auto;
    }

    
}