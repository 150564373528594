

.navbar-wrapper {
    position: fixed;
    top: 0;
    margin-top: min(3.47vw, 50px);
    width: 100%;
    height: min(8.68vw, 125px);
    background-color: transparent;
    transition: opacity ease 0.3s, margin-top ease 0.5s, background-color ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    
}

.absolute-background {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 100%);
    z-index: -1;
    transition: opacity ease 0.3s;
}


.test {
    width: 100px;
    height: 100px;
    background-color: blue;
}

.navbar-position-ref {
    width: 100%;
    height: min(13.02vw, 187.5px);
    top: 0;
    position: absolute;
    
}

.navbar-container {
    width: 100%;
    max-width: 1200px; /*here to adjust navbar width*/
    height: min(8.68vw, 125px);
    opacity: 1;
    position: relative;
    transition: opacity ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

}

.navbar-logo-container {
    width: min(19.86vw, 286.01px);
    height: min(5.90vw, 85px);
}

.navbar-links-container {
    display: flex;
    align-items: center;
    gap: min(1.67vw, 24px);
}

.navbar-buttons-container {
    display: flex;
    align-items: center;
    gap: min(3.33vw, 48px);
}

.navbar-button {
    height: 100%;
    font-family: 'Barlow Semi Condensed';
    font-weight: 600;
    color: white;
    transition: color ease 0.3s;
    cursor: pointer;
    font-size: min(1.25vw, 18px);
    line-height: 100%;
}

.navbar-button:hover {
    color: #BFFF78;
}

.navbar-book-button {
    height: min(3.47vw, 50px);
    padding-left: min(2.22vw, 32px);
    padding-right: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(6.94vw, 100px);
    border: 1px solid white;
    font-family: "Poppins";
    font-weight: 500;
    font-size: var(--desktop-16px);
    line-height: 100%;
    letter-spacing: -1px;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color ease 0.3s, color ease 0.3s;
}

.navbar-book-button:hover {
    background-color: white;
    color: black;
}

@media screen and (max-width: 478px) {

    

.navbar-wrapper {
    position: fixed;
    top: 0;
    margin-top: min(14.97vw, 62px);
    width: 100%;
    height: auto;
    background-color: transparent;
    transition: opacity ease 0.3s, margin-top ease 0.5s, background-color ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    
    
}

.absolute-background {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 100%);
    z-index: -1;
    transition: opacity ease 0.3s;
}


.test {
    width: 100px;
    height: 100px;
    background-color: blue;
}

.navbar-position-ref {
    width: 100%;
    height: min(13.02vw, 187.5px);
    top: 0;
    position: absolute;
    
}

.navbar-container {
    width: 100%;
    max-width: min(92.2vw, 382px); /*here to adjust navbar width*/
    height: auto;
    opacity: 1;
    position: relative;
    transition: opacity ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding-top: min(3.86vw, 16px);
    transition: padding-top ease 0.3s;

}

.navbar-logo-container {
    width: min(39.37vw, 163px);
    height: min(11.7vw, 48.44px);
}

.navbar-links-container {
    display: flex;
    align-items: center;
    gap: min(1.67vw, 24px);
}

.navbar-buttons-container {
    display: flex;
    align-items: center;
    gap: min(3.33vw, 48px);
}

.navbar-button {
    display: none;
    height: 100%;
    font-family: 'Barlow Semi Condensed';
    font-weight: 600;
    color: white;
    transition: color ease 0.3s;
    cursor: pointer;
    font-size: min(1.25vw, 18px);
    line-height: 100%;
}

.navbar-button:hover {
    color: #BFFF78;
}

.navbar-book-button {
    height: min(10.14vw, 42px);
    padding-left: min(7.36vw, 30.5px);
    padding-right: min(7.36vw, 30.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(14.19vw, 58.78px);
    border: 1px solid white;
    font-family: "Poppins";
    font-weight: 500;
    font-size: min(3.38vw, 14px);
    line-height: 100%;
    letter-spacing: -px;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color ease 0.3s, color ease 0.3s;
}

.navbar-book-button:hover {
    background-color: white;
    color: black;
}



    
}