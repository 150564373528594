
.slider-wrapper {
    width: 100%;
    padding-top: min(3.82vw, 55px);
    padding-bottom: min(3.82vw, 55px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
}

.slider-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider-row-column-left {
    height: 100%;
    width: auto;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
}

.svg-left-wrapper {
    width: min(13.75vw, 198px);
    height: min(4.38vw, 63px);
}

.slider-row-column-right {
    height: 100%;
}

.svg-right-wrapper {
    width: min(5.60vw, 80.64px);
    height: min(6.86vw, 98.82px);
}

.splide-container {
    width: 65%;
    position: relative;
}

.radial-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    z-index: 9;
}

.test-box {
    width: 100px;
    height: 100px;
    background-color: blue;
}

.club-container {
    display: flex;
    align-items: center;
    gap: min(0.99vw, 14.32px);
}

.club-shield {
    width: min(7.95vw, 114.55px);
    height: min(10.41vw, 149.93px);
}

.club-name {
    font-family: "Mont";
    font-size: min(0.97vw, 14px);
    line-height: min(1.17vw, 16.8px);
    color: #DFDFDF;
    letter-spacing: -0.5px;
    font-weight: 800;
}

@media screen and (max-width: 478px) {

    
.slider-wrapper {
    width: 100%;
    padding-top: min(4.1vw, 17px);
    padding-bottom: min(11.5vw, 47.6px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.slider-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: min(8.78vw, 36.36px);
}

.slider-row-column-left {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
}

.svg-left-wrapper {
    width: min(34.7vw, 143.8px);
    height: min(11vw, 45.6px);
}

.slider-row-column-right {
    height: 100%;
}

.svg-right-wrapper {
    display: none;
}

.splide-container {
    width: 100%;
    position: relative;
    background-color: black;
}

.radial-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    z-index: 9;
}

.test-box {
    width: 100px;
    height: 100px;
    background-color: blue;
}

.club-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(2.41vw, 10px);
}

.club-shield {
    width: min(20.11vw, 83.28px);
    height: min(26.3vw, 109px);
}

.club-name {
    font-family: "Mont";
    font-size: min(3.47vw, 14.4px);
    line-height: min(2.89vw, 12px);
    color: #DFDFDF;
    letter-spacing: -0.5px;
    font-weight: 800;
    text-align: center;
}
    
}